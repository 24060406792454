<template>
  <div class="newsletter-form">
    <p class="title-1">
      Sign up for our newsletter
    </p>
    <form
      class="form"
      @submit.prevent="handleSubscription"
    >
      <input
        type="text"
        class="input-text paragraph-1"
        placeholder="Your Email Address"
        v-model.trim="email"
      >
      <button
        class="action action-apply cta-text"
        type="submit"
      >
        Subscribe
      </button>
    </form>
    <p class="paragraph-1 text-neutral-700 scoop-text">
      Get the inside scoop of our new launches and experiences!
    </p>
    <p
      class="paragraph-1"
      v-if="msg.text"
      :class="msg.type"
    >
      {{ msg.text }}
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      email: '',
      msg: {
        type: 'success',
        text: ''
      }
    }
  },
  methods: {
    ...mapActions({
      'subscribeNewsletter': 'user/subscribeNewsletter'
    }),

    async handleSubscription () {
      this.msg.type = 'success'
      this.msg.text = ''
      const emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      if (!this.email) {
        this.msg.type = 'error'
        this.msg.text = 'For sneak peeks & BFF secrets - you don\'t want to miss this!'
      } else if (!emailCheck.test(this.email)) {
        this.msg.type = 'error'
        this.msg.text = 'Oops, we can’t seem to find this email - could you try again?'
      } else {
        this.$bus.$emit('notification-progress-start', this.$t('Subscribing...'))
        const { result } = await this.subscribeNewsletter(this.email)
        if (!result.status) {
          this.msg.type = 'error'
        } else {
          this.email = ''
          this.msg.type = 'success'
        }
        this.msg.text = result.message
        this.$bus.$emit('notification-progress-stop')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.newsletter-form {
  .title-1 {
    margin-bottom: 20px;
  }
  .form {
    display: flex;
    background-color: white;
    padding: auto 10px;
    margin: 0 auto 5px auto;
    .input-text {
      width: 295px;
      margin: 0;
      left: 0;
      z-index: 1;
      border: 1px solid var(--neutral-200);
      outline: none;
      padding: var(--spacing-lg);
      color: var(--neutral-900);
      border-right: none;
      border-radius: unset;
      @include for-mobile {
        width: 100%;
      }
    }
    .action-apply {
      float: right;
      cursor: pointer;
      padding: var(--spacing-xl);
      color: white;
      background: var(--bold-brown-500);
      transition: all 0.5s;
      border: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .paragraph-1 {
    margin-top: var(--spacing-xl);
    color: var(--neutral-700);
  }
  .success {
    color: var(--success-500);
  }

  .error {
    color: var(--danger-500);
  }
  .scoop-text {
    width: 77%;
  }
}
</style>
