<template>
  <div class="footer-top-left">
    <MNewsletterForm />
  </div>
</template>

<script>
import MNewsletterForm from 'common/components/molecules/footer/m-newsletter-form'
export default {
  name: 'MNewsletterSection',
  components: {
    MNewsletterForm
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.footer-top-left {
  max-width: 100%;
}
</style>
